import { styled } from '@mui/system';

const TableSeparateStyle = styled('div')(({ theme }) =>
    theme.unstable_sx({
      '& .TableCell': {
        border: '1px solid #949494', 
        /*color: '#ffffff',*/ 
        textAlign: 'center'
      },
      '& .TableCell.total': {
        height: '50px'
      },
      '& table': {
            backgroundColor: '1px solid #949494'
      },
      '& table thead tr': {
            backgroundColor: '#006400',
      },
      '& table thead tr th': {
            color: '#ffffff',
      },
      '& table thead tr th .MuiCheckbox-root': {
            color: '#ffffff',
      },
      '& table tbody tr:last-child td, & table tbody tr:last-child td': {
            border: '1px solid #949494'
      },
      '& .MuiTableContainer-root': {
            borderRadius: 0  
      }
    }),
  );

export default TableSeparateStyle;