import MuiDrawer from '../../components/MuiDrawer';
import Schwangerschaftserstuntersuchung_Calculation from './Schwangerschaftserstuntersuchung_Calculation';
import Box from '@mui/material/Box';
import { colorName } from '../../components/colors/Colors';

import { DIAGNOSE_GRAVIDITAET_FACTOR_MAPPING } from './constants/Factor_Map/DIAGNOSE_GRAVIDITAET_FACTOR_MAPPING';

import { TERMIN2_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN2_FACTOR_MAPPING'; //Default Factor Mappging for Schwangerschaftserstuntersuchung
                                                                                        //This will be used for Termin 2                                                                                        

import { TERMIN3_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN3_FACTOR_MAPPING'; //Default Factor Mappging for Schwangerschaftserstuntersuchung
import { TERMIN4_FACTOR_MAPPING } from './constants/Factor_Map/TERMIN4_FACTOR_MAPPING';

import { GOAE_PAGE_TYPES } from '../../constants/goaePageTypeList';

export default function Schwangerschaftserstuntersuchung_Main(props) {

  const { GOAE_PAGE_TYPE_ID } = props;

  const renderPage = () => {
    switch(GOAE_PAGE_TYPE_ID) {
      case GOAE_PAGE_TYPES.DIAGNOSE_GRAVIDITAET.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={DIAGNOSE_GRAVIDITAET_FACTOR_MAPPING} 
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor bei der 1. Diagnose (Gravidität möglich) ein:"
                  enableRightTable_H_and_H1_Calculation={true}
                />;
      case GOAE_PAGE_TYPES.TERMIN_2.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN2_FACTOR_MAPPING} 
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 2. Termin (9+0 - 9+6. SSW) ein:"
                />;
      case GOAE_PAGE_TYPES.TERMIN_3.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN3_FACTOR_MAPPING}
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 3. Termin (12+0 - 13+3 SSW) ein:" 
               />;
      case GOAE_PAGE_TYPES.TERMIN_4.id :
        return <Schwangerschaftserstuntersuchung_Calculation 
                  factorMapping={TERMIN4_FACTOR_MAPPING}
                  kasuistikenTitle="Bitte geben Sie die GOÄ-Ziffern, die Anzahl und Ihren individuellen Faktor beim 4. Termin (16+0 - 17 +3 SSW) ein:"
                />;
      default:
        return <Schwangerschaftserstuntersuchung_Calculation factorMapping={TERMIN2_FACTOR_MAPPING} />;
    }
  };

  return (
    <Box sx={{backgroundColor: colorName.gray }}>
            <MuiDrawer>
              { renderPage() }
            </MuiDrawer>
    </Box>
  );
}